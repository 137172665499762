// header
.header {
    width: 100%;
    background-color: var(--background-header);

    .container {
        height: 75px;
    }

    // logo
    .logo {
        img {
            display: block;
            height: 60px;
        }
    }

    // profile
    .profile {
        p {
            color: var(--typography-header);
            font-size: 14px;
            @include mainFontSemiBold;
        }

        .button {
            padding: 8px 30px;
            margin-left: 15px;
        }
    }
}