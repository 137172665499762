@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

// colours
$overlay-grey: rgba(0, 0, 0, 0.3);

// misc
$border-radius: 3px;

@mixin fullWidth() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// media queries
@mixin screenSize($size) {
    @if $size == lgDesktop {
        // large desktop
        @media only screen and (max-width: 1599px) {
            @content;
        }
    } @else if $size == mdDesktop {
        // medium desktop
        @media only screen and (max-width: 1279px) {
            @content;
        }
    } @else if $size == smDesktop {
        // small desktop
        @media only screen and (max-width: 1099px) {
            @content;
        }
    } @else if $size == lsTablet {
        // landscape tablet
        @media only screen and (max-width: 1023px) {
            @content;
        }
    } @else if $size == ptTablet {
        // portrait tablet
        @media only screen and (max-width: 800px) {
            @content;
        }
    } @else if $size == lgMobile {
        // large mobile
        @media only screen and (max-width: 767px) {
            @content;
        }
    } @else if $size == mdMobile {
        // medium mobile
        @media only screen and (max-width: 479px) {
            @content;
        }
    } @else if $size == smMobile {
        // small mobile
        @media only screen and (max-width: 413px) {
            @content;
        }
    } @else {
        // custom size
        @media only screen and (max-width: $size) {
            @content;
        }
    }
}