@import 'variables.scss';

html, body, #root, #web-body {
    height: 100%;
    overflow: hidden;
    @include mainFont();
}

// wrapper
#web-body {
    display: flex;
    flex-direction: column;
    background-color: var(--background-body);
    color: var(--typography-primary);
}

#web-wrapper {
    flex: 1;
    overflow: auto;
}

.container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1300px;
}

.page-padding {
    padding: 20px 0;
}

@import 'components/button.scss';
@import 'components/form.scss';
@import 'components/header.scss';
@import 'components/modal.scss';
@import 'components/table.scss';
@import 'components/typography.scss';